/*
    A class for the form students use to sign-up weekly.
*/

import React, {Component} from 'react';
import {Button, Form} from "react-bootstrap";

import {PATH_HOME, GetEditStudentPathByLocation} from './App';
import {Capitalize, CreateNameLists, GetUpcomingDateNameList,
        AnyAgeFeatureEnabled, GuestsCanRequestFriendsByName, GuestsCanRequestFriendsByEmail,
        ExtractCountryCityCommunity, ValidateEmail,
        PageLoading, ErrorPopUp, NoDatesAvailablePopUp, SendFormToServer,
        GLOBAL_ERROR_MESSAGES} from "./Util";
import {DateDropdown} from "./subcomponents/DateDropdown";
import {EmailField} from './subcomponents/EmailField';
import {RadioOptions} from './subcomponents/RadioOptions';
import {PasswordField} from './subcomponents/PasswordField';
import {StudentDropdown} from './subcomponents/StudentDropdown';
import {StudentGuestSelection, DEFAULT_GUEST_INPUT_FIELDS} from './subcomponents/StudentGuestSelection';

import "./styles/FormPage.css";
import "./styles/WeeklyForm.css";

//TODO: Use cookies to see if they signed up as a couple or selected that field before?
//TODO: Deselect the marriage options by default but treat them as selected

export const MAX_FRIENDS = 5;
export const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_EMAIL: "Invalid email!",
    INVALID_PASSWORD: "Incorrect password!",
    INVALID_DATE: "Invalid date!",
    INVALID_FRIENDS: "At least one of the friends specified isn't linked to any account!",
    INVALID_SELF_FRIEND_REQUEST: "You can't request yourself as a friend!",
    INVALID_MALEGUESTS: "Invalid male guest!",
    INVALID_FEMALEGUESTS: "Invalid female guest!",
    INVALID_GUESTALLERGIES: "Invalid guest allergies!",
    INVALID_GUESTMINAGE: "Invalid guest minimum age!",
    INVALID_GUESTMAXAGE: "Invalid guest maximum age!",
    INVALID_GUESTVACCINATED: "Invalid guest vaccination status!",
    INVALID_AGERANGE: "Guest ages must be greater than 0 and the minimum age must be less than the maximum age!",
    NO_ACCOUNT_FOUND: "No {GUEST_TYPE} account with that email exists!",
    ALREADY_SIGNED_UP: "You've already signed up for that week!",
    ALREADY_SIGNED_UP_TO_HOST: "You've already signed up to host that week. You can't join a meal as well!",
    ALREADY_SIGNED_UP_OTHER_COMMUNITY: "You've already signed up for a meal that week in the {ERR_COMMUNITY} community!",
    DEADLINE_PASSED: "The deadline has already passed!\nContact {MAINTAINER} at {MAINTAINER_CONTACT} and maybe there will still be space for you.",
    MISSING_FEATURE_FIELDS: "Your account is missing data required to sign up for a meal in the {COMMUNITY} community! "
                          + "\nPlease go to {EDIT_URL} to update your details.",
};


export class WeeklyStudent extends Component
{
    /**
     * Constructs a new instance of the WeeklyStudent component.
     * @param {Object} props - The props object containing the component's properties.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            loaded: false,
            emailInput: "",
            passwordInput: "",
            dateInput: "",
            holyDayInput: "",
            mealInput: "",
            signUpCouple: true,
            friendEmailsInput: "",
            friendsInput: [],
            ...DEFAULT_GUEST_INPUT_FIELDS,
            dateList: [],
            allergyNameList: [],
            foodPreferenceNameList: [],
            showedErrorPopUp: false,
            errorMsg: "",
            errorMsgCommunity: "",
            invalidEmail: "",
            locationDetails: props.locationDetails,
        }
    }

    /**
     * Loads data from the server when the page loads.
     */
    async componentDidMount()
    {
        //Load all data at once
        let [nameLists, dateList] = await Promise.all
        ([
            CreateNameLists(),
            GetUpcomingDateNameList(this.state.locationDetails)
        ]);
        let allergies = nameLists ? nameLists.allergies : null;
        let foodPreferences = nameLists ? nameLists.foodPreferences : null;

        if (dateList.length >= 1)
        {
            //Only offer the student days within the next six days
            let sixDaysAway = new Date();
            sixDaysAway.setDate(sixDaysAway.getDate() + 6);
            dateList = dateList.filter(date => new Date(date.date) <= sixDaysAway);
        }

        if (this.state.loaded)
            return; //Prevent the pop-up for displaying multiple times

        this.setState
        ({
            loaded: allergies && foodPreferences && dateList,
            allergyNameList: allergies,
            foodPreferenceNameList: foodPreferences,
            dateList: dateList,
            dateInput: (dateList.length > 0) ? dateList[0].date : this.state.dateInput, //Default to the first entry if there is one
            holyDayInput: (dateList.length > 0) ? dateList[0].type : this.state.holyDayInput, //Default to the first entry if there is one
            mealInput: (dateList.length > 0) ? dateList[0].meal : this.state.mealInput, //Default to the first entry if there is one
        });

        if (dateList.length === 0) //No dates available
            NoDatesAvailablePopUp(this.state.locationDetails);
    }

    /**
     * Sets the state of the component and waits for the state update to complete.
     * @param {Object} newState - The new state object to set.
     * @returns {Promise} A promise that resolves when the state update is complete.
     */
    async setStateAndWait(newState)
    {
        return new Promise(resolve => this.setState(newState, resolve));
    }

    /**
     * Checks if all required fields in the form are filled.
     * @returns {boolean} Whether all required fields are filled.
     */
    allRequiredFieldsFilled()
    {
        return this.state.emailInput !== ""
            && this.state.passwordInput !== ""
            && this.state.dateInput !== ""
            && this.state.holyDayInput !== ""
            && this.state.mealInput !== ""
            && (!this.bringingGuests()
            //Only needs to be filled out if a guest is coming
             || (this.state.guestVaccinatedInput !== ""
              && (this.state.guestMinAgeInput !== "" || !AnyAgeFeatureEnabled(this.state.locationDetails))
              && (this.state.guestMaxAgeInput !== "" || !AnyAgeFeatureEnabled(this.state.locationDetails))));
    }

    /**
     * Checks if the input email is a valid email.
     * @returns {boolean} Whether the email is valid.
     */
    validEmail()
    {
        return ValidateEmail(this.state.emailInput);
    }

    /**
     * Checks if the input guest age range is valid.
     * @returns {boolean} Whether the guest age range is valid.
     */
    validAgeRange()
    {
        let minAge = Number(this.state.guestMinAgeInput);
        let maxAge = Number(this.state.guestMaxAgeInput);

        if (isNaN(minAge) || isNaN(maxAge))
            return false;

        if (minAge === 0 || maxAge === 0)
            return false; //Both need to be filled out

        return minAge <= maxAge;
    }

    /**
     * Gets the error message (if present) at the time of form submission.
     * @returns {string} The error message symbol.
     */
    getErrorMessage()
    {
        let errorMsg = "";

        if (!this.allRequiredFieldsFilled())
            errorMsg = "MISSING_REQUIRED_FIELD";
        else if (!this.validEmail())
            errorMsg = "INVALID_EMAIL";
        else if (this.bringingGuests() && AnyAgeFeatureEnabled(this.state.locationDetails) && !this.validAgeRange())
            errorMsg = "INVALID_AGERANGE";
        else if (this.requestingSelfAsFriend())
            errorMsg = "INVALID_SELF_FRIEND_REQUEST";

        return errorMsg;
    }

    /**
     * Checks if an error message symbol is the last shown error message.
     * @param {string} errorMsg - The error message symbol to check.
     * @returns {boolean} Whether the error message symbol was last shown.
     */
    isErrorMessage(errorMsg)
    {
        return this.state.errorMsg === errorMsg;
    }

    /**
     * Checks if the email input is for an account that doesn't exist.
     * @returns {boolean} Whether the email input is for an account that doesn't exist.
     */
    emailNotAccount()
    {
        return this.state.invalidEmail !== ""
            && this.state.invalidEmail === this.state.emailInput;
    }

    /**
     * Checks if the user is bringing guests.
     * @returns {boolean} Whether the user is bringing at least one guest of either gender.
     */
    bringingGuests()
    {
        return this.state.maleGuestsInput > 0 || this.state.femaleGuestsInput > 0;
    }

    /**
     * Checks if the user is requesting themselves as a friend.
     * @returns {boolean} Whether the user is requesting themselves as a friend.
     */
    requestingSelfAsFriend()
    {
        return this.state.friendsInput.includes(this.state.emailInput);
    }

    /**
     * Updates the chosen friend list. At most MAX_FRIENDS friends can be chosen at once.
     * @param {string[]} newFriendList - The new list of friend emails to save.
     */
    updateFriends(newFriendList)
    {
        this.setState({friendsInput: newFriendList})
    }

    /**
     * Updates the chosen friend list using emails. At most MAX_FRIENDS friends can be chosen at once.
     * @param {string} emails - The list of emails to save.
     */
    updateFriendsUsingEmails(emails)
    {
        let emailList = emails.split(",").map(email => email.toLowerCase().trim());
        this.setState
        ({
            friendEmailsInput: emails,
            friendsInput: emailList.splice(0, MAX_FRIENDS), //Only take the first MAX_FRIENDS amount
        });
    }

    /**
     * Sets the input values for guest selection.
     * @param {Object} updatedValues - The updated values to set.
     * @param {number} updatedValues.maleGuestsInput - The number of male guests.
     * @param {number} updatedValues.femaleGuestsInput - The number of female guests.
     * @param {string[]} updatedValues.guestAllergiesInput - The allergies of the guests.
     * @param {string[]} updatedValues.guestFoodPreferenceInput - The food preferences of the guests.
     * @param {number} updatedValues.guestMinAgeInput - The minimum age of the guests.
     * @param {number} updatedValues.guestMaxAgeInput - The maximum age of the guests.
     * @param {boolean} updatedValues.guestVaccinatedInput - Indicates if the guests are vaccinated.
     * @param {string[]} updatedValues.allergyNameList - The updated list of allergy names.
     * @param {string[]} updatedValues.foodPreferenceNameList - The updated list of food preference names.
     */
    setGuestsInput(updatedValues)
    {
        this.setState(updatedValues);
    }

    /**
     * Submits the sign-up form.
     * @param {Event} e - The default event for submitting a form.
     */
    async submitSignUp(e)
    {
        e.preventDefault(); //Prevent page reload
        let errorMsg = this.getErrorMessage();

        if (errorMsg === "") //No error
        {
            let data =
            {
                //If updating this data, update subcomponents/maintainer/WeeklyStudentButton.js as well
                email: this.state.emailInput.toLowerCase(),
                password: this.state.passwordInput,
                date: this.state.dateInput,
                type: this.state.holyDayInput,
                meal: this.state.mealInput,
                friends: this.state.friendsInput,
                signUpCouple: this.state.signUpCouple,
                maleGuests: Number(this.state.maleGuestsInput),
                femaleGuests: Number(this.state.femaleGuestsInput),
                guestAllergies: this.bringingGuests() ? this.state.guestAllergiesInput : [],
                guestFoodPrefs: this.bringingGuests() ? this.state.guestFoodPreferenceInput : [],
                guestMinAge: (this.state.guestMinAgeInput === "") ? 0 : this.bringingGuests() ? Number(this.state.guestMinAgeInput) : 0,
                guestMaxAge: (this.state.guestMaxAgeInput === "") ? 0 : this.bringingGuests() ? Number(this.state.guestMaxAgeInput) : 0,
                guestVaccinated: this.bringingGuests() ? this.state.guestVaccinatedInput : true, //Only relevant if guests are brought
            };

            let signOff = (this.state.holyDayInput === "Shabbat") ? "Shabbat Shalom!" : "Chag sameach!";
            await SendFormToServer(data, this, "/weeklystudentsignup", `Sign-up complete!\n${signOff}`,
                                   this.state.locationDetails, PATH_HOME);
        }
        else
        {
            this.setState({errorMsg: errorMsg});
            this.errorPopUp(errorMsg);
        }
    }

    /**
     * Displays an error pop-up.
     * @param {string} errorSymbol - The error symbol for the message to be shown on the pop-up.
     */
    errorPopUp(errorSymbol)
    {
        const editUrl = `${window.location.origin}${GetEditStudentPathByLocation(this.state.locationDetails)}`;
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        text = text.replaceAll("{GUEST_TYPE}", this.state.locationDetails.guestType);
        text = text.replaceAll("{MAINTAINER}", this.state.locationDetails.maintainerName);
        text = text.replaceAll("{MAINTAINER_CONTACT}", this.state.locationDetails.maintainerContact);
        text = text.replaceAll("{ERR_COMMUNITY}", this.state.errorMsgCommunity);
        text = text.replaceAll("{COMMUNITY}", this.state.locationDetails.community);
        text = text.replaceAll("{EDIT_URL}", editUrl);
        ErrorPopUp(text);
    }

    /**
     * Sets the date inputs for the form.
     * @param {string} date - The string date to set.
     * @param {string} holyDay - The holy day to set.
     * @param {string} meal - The meal to set.
     */
    setDateInputs(date, holyDay, meal)
    {
        this.setState({dateInput: date, holyDayInput: holyDay, mealInput: meal});
    }

    /**
     * Renders the WeeklyStudent component.
     * @returns {JSX.Element} The rendered component.
     */
    render()
    {
        let {country, city, community} = ExtractCountryCityCommunity(this.state.locationDetails);
        const friendWarning = <><b>Note</b> your request may not always be satisfied, and there is a high chance you will not be placed with more than one friend.</>;

        if (!this.state.loaded)
            return PageLoading();

        return (
            <div className="form-page">
                <h1 className="form-title weekly-student-form-title">Weekly {Capitalize(this.state.locationDetails.guestType)} Sign-Up</h1>
                <h2 className="form-title weekly-student-form-title">{community}, {city}, {country}</h2>
                <div className="mb-3"><Form.Text>This form closes Tuesday night for Shabbat meals.</Form.Text></div>

                <Form onSubmit={(e) => this.submitSignUp(e)}>
                    {/*Email Input*/}
                    <EmailField
                        email={this.state.emailInput}
                        setParentEmail={(email) => this.setState({emailInput: email})}
                        isInvalid={() => !this.validEmail() || this.emailNotAccount()} />

                    {/*Password Input*/}
                    <PasswordField
                        password={this.state.passwordInput}
                        showForgotPasswordLink={true}
                        setParentPassword={(password) => this.setState({passwordInput: password})}
                        personType={this.state.locationDetails.guestType}
                        basePath={this.state.locationDetails.basePath} />

                    {/*Date Input*/}
                    <DateDropdown dateList={this.state.dateList} dateInput={this.state.dateInput}
                                  holyDayInput={this.state.holyDayInput} mealInput={this.state.mealInput}
                                  setParentDateInputs={this.setDateInputs.bind(this)}
                                  text="Which date are you signing up for?" />

                    {/*Friends Input*/}
                    {
                        GuestsCanRequestFriendsByName(this.state.locationDetails) ?
                            <StudentDropdown
                                students={this.state.friendsInput}
                                fieldLabel="Would you like to be placed with anyone if they sign up too?"
                                fieldDesc={friendWarning}
                                errorCode="INVALID_FRIENDS"
                                maxAllowed={MAX_FRIENDS}
                                setParentStudents={this.updateFriends.bind(this)}
                                isErrorMessage={this.isErrorMessage.bind(this)}
                                locationDetails={this.state.locationDetails} />
                        : GuestsCanRequestFriendsByEmail(this.state.locationDetails) ?
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-1">Would you like to be placed with anyone if they sign up too?</Form.Label>
                                <br/>
                                <Form.Text className="mb-1">
                                    Enter their emails and separate with <span className="fw-bold">commas</span>.
                                </Form.Text>
                                <br/>
                                <Form.Text className="text-muted">
                                    For security reasons, emails are not provided.
                                    If a friend wants to join you, they'll likely have no problem sharing their email.
                                </Form.Text>
                                <br/>
                                <Form.Text className="mb-1">
                                    {friendWarning}
                                </Form.Text>
                                <br/>
                                <Form.Control
                                    type="text"
                                    placeholder="friend1@example.com, friend2@example.com"
                                    className={`form-control ${this.state.friendsInput.length > 0 && this.isErrorMessage("INVALID_FRIENDS") ? 'is-invalid' : ''}`}
                                    value={this.state.friendEmailsInput}
                                    onChange={(e) => this.updateFriendsUsingEmails(e.target.value)}
                                />
                            </Form.Group>
                        :
                            ""
                    }

                    {/*Guests Input*/}
                    <StudentGuestSelection
                        allergyNameList={this.state.allergyNameList}
                        foodPreferenceNameList={this.state.foodPreferenceNameList}
                        setParentGuestDetails={this.setGuestsInput.bind(this)}
                        isErrorMessage={this.isErrorMessage.bind(this)}
                        locationDetails={this.state.locationDetails} />

                    {/*Single or Married Input*/}
                    <RadioOptions
                        input={this.state.signUpCouple}
                        radioLabels={[{label: "With Spouse", val: true}, {label: "Alone", val: false}]}
                        fieldLabel="Are you signing up with your spouse?"
                        fieldDesc={
                            <>
                                Ignore this if you didn't originally register as a couple.
                                <br/>
                                If you set your spouse in this form to come along as a guest, select "Alone".
                            </>
                        }
                        setParentInput={(val) => this.setState({signUpCouple: val})} />

                    {/* Submit Button */}
                    <div className="submit-form-button-container mt-2">
                        <Button variant="success" className="submit-form-button" type="submit">
                            Sign-Up
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

export default WeeklyStudent;
